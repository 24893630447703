
import { fetchPost } from './request.js'

// 超级管理员操作（目录管理、角色管理、用户管理、数据字典管理、标签管理）---- 人员管理 企业管理

/**
 * 目录管理
 */
// 用户登录成功后获取自己的目录树 back_manager 后台 memb_center前台
export const AdminMyDirectoryTree = (params) => {
  return fetchPost('/system/w/user/viewMenu/list', params)
}
// 全部目录结构列表
export const AdminGetDirectoryTree = (params) => {
  return fetchPost('/system/i/menu/tree', params)
}
// 获取item项的详细信息
export const AdminDirectoryTreeItem = (params) => {
  return fetchPost('/system/i/menu/get', params)
}
// 删除item项
export const AdminDirectoryTreeDel = (params) => {
  return fetchPost('/system/i/menu/delete', params)
}
// 编辑item项信息
export const AdminDirectoryTreeUpdate = (params) => {
  return fetchPost('/system/i/menu/update', params)
}
// 新增item项
export const AdminDirectoryTreeAdd = (params) => {
  return fetchPost('/system/i/menu/insert', params)
}
// -------------------------------------------------------------不知道干啥的
export const AdminDirectoryViewTree = (params) => {
  return fetchPost('/system/i/menu/viewTree', params)
}

/**
 *  角色管理
 */
// 获取角色列表
export const AdminGetRoleList = (params) => {
  return fetchPost('/system/i/role/list', params)
}
// 获取角色详情
export const AdminGetRoleItem = (params) => {
  return fetchPost('/system/i/role/get', params)
}
// 新增角色
export const AdminAddRole = (params) => {
  return fetchPost('/system/i/role/insert', params)
}
// 修改角色信息
export const AdminEditRole = (params) => {
  return fetchPost('/system/i/role/update', params)
}
// 删除角色
export const AdminDelRole = (params) => {
  return fetchPost('/system/i/role/delete', params)
}
// 获取相对角色可显示的目录列表
export const AdminGetRoleMenu = (params) => {
  return fetchPost('/system/i/role/menuIds', params)
}
// 修改相对角色可显示的目录列表
export const AdminUpdateRoleMenu = (params) => {
  return fetchPost('/system/i/role/updateMenu', params)
}

/**
 *  用户管理
 */
// 获取用户列表
export const AdminGetUserList = (params) => {
  return fetchPost('/info/i/infoUserMessage/selectUser', params)
}
// 用户停用启用
export const AdminUserStatusUpdate = (params) => {
  return fetchPost('/system/i/user/login/batUpdate', params)
}
// 重置用户密码
export const AdminUserResetPwd = (params) => {
  return fetchPost('/system/i/user/pwd/reset', params)
}
// 获取数据字典列表
export const AdminGetUserRoles = (params) => {
  return fetchPost('/system/i/user/getRoleList', params)
}
// 获取数据字典列表
export const AdminGetAllRoleList = (params) => {
  return fetchPost('/system/i/role/allList', params)
}
// 获取数据字典列表
export const AdminUserRoleUpdate = (params) => {
  return fetchPost('/system/i/user/roleUpdate', params)
}
// 获取数据字典列表
export const AdminUserEdit = (params) => {
  return fetchPost('/info/i/infoUserMessage/updateUser', params)
}

/**
 *  数据字典
 */
// 获取数据字典列表
export const AdminGetDicList = (params) => {
  return fetchPost('/system/i/dict/list', params)
}
// 获取字典详情
export const AdminGetDicItem = (params) => {
  return fetchPost('/system/i/dict/get', params)
}
// 添加字典
export const AdminDicAdd = (params) => {
  return fetchPost('/system/i/dict/insert', params)
}
// 修改字典详情
export const AdminDicUpdate = (params) => {
  return fetchPost('/system/i/dict/update', params)
}
// 删除字典
export const AdminDicDel = (params) => {
  return fetchPost('/system/i/dict/delete', params)
}
// 导出数据字典
export const AdminDicFileDown = (params) => {
  return Request.downLoadFile('/system/i/dict/getDictJsFile', params)
}

/**
 * 标签管理
 */
// 获取标签列表
export const AdminGetTagList = (params) => {
  return fetchPost('/info/i/infoTags/getPage', params)
}
// 删除标签
export const AdminDelTag = (params) => {
  return fetchPost('/info/i/infoTags/deleteTags', params)
}
// 增加标签
export const AdminAddTag = (params) => {
  return fetchPost('/info/i/infoTags/addTags', params)
}
// 修改标签信息
export const AdminUpdateTag = (params) => {
  return fetchPost('/info/i/infoTags/updateTags', params)
}
// 获取标签相关用户列表
export const AdminTagToCompany = (params) => {
  return fetchPost('/info/i/infoTags/getUserPage', params)
}
// 删除标签用户
export const AdminDeleteTagCompany = (params) => {
  return fetchPost('/info/i/infoTags/deleteUserTags', params)
}

/**
 *  人员管理
 */
export const StaffList = (params) => { // 运营客服列表
  return fetchPost('/info/i/infoUserMessage/csAndOperation', params)
}
export const AdminPersonnelManagementList = (params) => {
  return fetchPost('/system/i/user/userList', params)
}
// 获取人员列表
export const userDataAdd = (params) => {
  return fetchPost('/system/i/user/insert', params)
}
// 增加人员
export const AdminUserAdd = (params) => {
  return fetchPost('/system/i/user/insert', params)
}
// 删除人员
export const AdminDeleteUser = (params) => {
  return fetchPost('/system/i/user/batDeleteWithPo', params)
}
// 获取人员详情
export const AdminGetUserDetail = (params) => {
  return fetchPost('/system/i/user/getByIdWithPo', params)
}
export const StaffUpdateOperation = (params) => { // 给客服分配运营
  return fetchPost('/info/i/infoUserMessage/csUpdateOperation', params)
}
export const UserUpdateStaff = (params) => { // 给用户分配客服
  return fetchPost('/info/i/InfoOperateUserController/userInCs', params)
}
export const EditAdminUser = (params) => { // 编辑后台用户
  return fetchPost('/info/i/infoUserMessage/updateUserName', params)
}
/**
 *  用户管理
 */
export const UserList = (params) => { // 用户管理列表
  return fetchPost('/info/i/InfoOperateUserController/userList', params)
}
/**
 *  运营-企业管理
 */
export const BusinessList = (params) => { // 列表
  return fetchPost('/large/i/plateAccount/operatorPage', params)
}
export const BusinessDetail = (params) => { // 详情
  return fetchPost('/large/i/plateAccount/operationDetails', params)
}
